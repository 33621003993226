import Navigation from "./navigation/Navigation";
import Footer from "./footer/Footer";
import { Box } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ContentErrorFallback } from "../../utils/misc";

interface LayoutProps {
  children: React.ReactNode;
  locale: string;
  locales: string[];
  defaultLocale: string;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>
}

const Layout = ({ children, locale, locales, defaultLocale, globalState, setGlobalState }: LayoutProps):JSX.Element => {
  return (
    <Box>
      <Navigation globalState={globalState} setGlobalState={setGlobalState} />
      <ErrorBoundary FallbackComponent={ContentErrorFallback} >
        <Box mt={{base: '0', lg: '132px'}}>
          {children}
        </Box>
      </ErrorBoundary>
      <Footer globalState={globalState} setGlobalState={setGlobalState} />
    </Box>
  )
};

export default Layout;
