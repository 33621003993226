import {
  Box,
  Container,
  Divider,
  SimpleGrid,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import FooterAddress from './FooterAddress';
import FooterBottomBar from './FooterBottomBar';
import FooterContact from './FooterContact';
import FooterJournals from './FooterJournals';
import FooterLinks from './FooterLinks';
import FooterUsefulLinks from './FooterUsefulLinks';

export default function Footer(props: {globalState: GlobalState, setGlobalState: Dispatch<SetStateAction<GlobalState>>}):JSX.Element {
  return (
    <Box
      bg={'brand.darkgray.800'}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'container.xl'} py={10} gap="5">
        <SimpleGrid templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }} spacing={8}>
          <FooterAddress />
          <FooterJournals globalState={props.globalState} />
          <FooterUsefulLinks globalState={props.globalState} />
          <FooterContact globalState={props.globalState} setGlobalState={props.setGlobalState} />
        </SimpleGrid>

        <Divider borderColor="brand.darkgray.400" />
        
        <FooterLinks globalState={props.globalState} />
        <FooterBottomBar />
      </Container>
    </Box>
  );
}