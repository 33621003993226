import { Divider, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { RecentJournalLink } from "../navigation/subnavs/TripJournalsSubNav";

const FooterJournals = (props:{globalState: GlobalState}): JSX.Element => {
  return (
    <Stack>
      <Text
        as="p"
        mb="0"
        fontWeight={"bold"}
        color="brand.darkgray.200"
      >
        LATEST TRIP JOURNALS
      </Text>

      <Divider w="150px" borderColor="brand.darkgray.400" />

      <SimpleGrid columns={{ base: 1 }} gridGap={5}>
        {
          props.globalState.recentJournals.slice(0,5).map((journal: RecentJournal, index: number) => {
            return (
              <RecentJournalLink key={index} journal={journal} textColor="brand.darkgray.200" />
            )
          })
        }
      </SimpleGrid>
    </Stack>
  )
}

export default FooterJournals;