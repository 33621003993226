import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/react";
import SocialButton from "../SocialButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "../../misc/contact-form/ContactForm";
import { Dispatch, SetStateAction } from "react";

const FooterContact = ({ globalState, setGlobalState }: { globalState: GlobalState, setGlobalState: Dispatch<SetStateAction<GlobalState>>}):JSX.Element => {
  return (
      <Flex direction="column" justifyContent={'space-between'}>
        <Box>
          <Text 
            as="p"
            mb="2"
            fontWeight={"bold"}
            color="brand.darkgray.200"
          >
            CONTACT US
          </Text>

          <Divider w="150px" borderColor="brand.darkgray.400" />
        </Box>

        <Box w="100%">
          <ContactForm
            blok={{
              colorScheme: 'dark', 
              showOptionalToggle: true, 
              showLabels: false, 
              submitText: 'Request Info'
            }} 
            globalState={globalState}
            setGlobalState={setGlobalState}
          />
        </Box>
          
        <Box>
          <Text
            as="p"
            fontWeight={"bold"}
            color="brand.darkgray.200"
            pt="5"
          >
            FOLLOW US
          </Text>

          <Divider w="150px" borderColor="brand.darkgray.400" mb="3" />

          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Facebook'} href={'https://www.facebook.com/PrayingPelicanMissions'}>
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </SocialButton>
            <SocialButton label={'Twitter'} href={'https://twitter.com/PrayingPelican'}>
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'https://www.instagram.com/PrayingPelicanMissions/'}>
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'https://www.youtube.com/c/PrayingPelicanMissions'}>
              <FontAwesomeIcon icon={['fab', 'youtube']} />
            </SocialButton>
          </Stack>
        </Box>
      </Flex>
  )
}

export default FooterContact;